<template>
  <div class="bank-info">
    <BaseHeaderBar title="Informasi Bank" :showCustomerService="false" :showBackButton="true" color="#333" bgColor="#FFF" />
    <form class="bank-info-content" @submit.prevent="onSubmit">
      <div class="form-content">
        <div class="input-block select-block">
          <input type="text" readonly :value="bankName" placeholder="Nama Bank" @click="showPicker = true">
          <img src="@/assets/img/arrow-down.png" class="arrow-down">
          <van-popup v-model="showPicker" round position="bottom">
            <van-picker show-toolbar :columns="columns" value-key="bank_name" :default-index="defaultIndex" cancel-button-text="Batal" confirm-button-text="Yakin" @cancel="showPicker = false" @confirm="onConfirm" />
          </van-popup>
        </div>
        <div class="input-block">
          <input v-model.trim="account" type="tel" maxlength="20" placeholder="Rekening Bank">
        </div>
        <div class="input-block">
          <input v-model.trim="reAccount" type="tel" maxlength="20" placeholder="Konfirmasi rekening bank">
        </div>
      </div>
      <button type="submit" class="submit-btn">Submit</button>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'
import { Popup, Picker, Toast } from 'vant'
import { validateBankAccount } from '@/utils/validator'
import request from '@/utils/request'

Vue.use(Popup)
Vue.use(Picker)

export default {
  name: 'BankInfo',
  data () {
    return {
      bankName: '',
      account: '',
      reAccount: '',
      columns: [],
      showPicker: false,
      defaultIndex: 0
    }
  },
  created () {
    this.getBankList()
  },
  methods: {
    getBankList () {
      request.post('badrnkInfoPage')
        .then(res => {
          this.bankName = res.badrnkName
          this.account = this.reAccount = res.badrnkAccount
          const bankList = res.badrnk_list || []
          this.defaultIndex = Math.floor(bankList.length / 2)
          this.columns = res.badrnk_list
          this.abbrBankName = res.gadrteID
        })
    },
    validate () {
      if (!this.bankName) {
        Toast('Silakan pilih nama bank')
        return false
      }
      if (!this.account) {
        Toast('Silakan masukkan nomor rekening bank')
        return false
      }
      if (!this.reAccount) {
        Toast('Silakan konfirmasi nomor rekening bank')
        return false
      }
      if (!validateBankAccount(this.account)) {
        Toast('Format nomor rekening bank salah')
        return false
      }
      if (this.account !== this.reAccount) {
        Toast('Kedua nomor rekening yang dimasukkan tidak sama')
        return false
      }
      return true
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        badrnkName: this.bankName,
        gadrteID: this.abbrBankName,
        badrnkAccount: this.account
      }
      request.post('badrnkInfosubmit', postData)
        .then(() => {
          Toast('Pembaruan data bank berhasil')
          this.$router.back()
        }).finally(() => {
          this.flag = false
        })
    },
    onConfirm (value) {
      this.bankName = value.bank_name
      this.abbrBankName = value.gate_id
      this.showPicker = false
    }
  }
}
</script>

<style scoped lang="less">
.bank-info {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.bank-info-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
}

.form-content {
  padding-left: 30px;
  padding-right: 30px;
}

.input-block {
  &:nth-child(n+2) {
    margin-top: 16px;
  }

  input {
    display: block;
    width: 100%;
    height: 88px;
    line-height: 88px;
    border: 2px solid #2952E5;
    border-radius: 8px;
    padding-left: 30px;
    box-sizing: border-box;
    font-size: 30px;
    color: #333;
    background-color: #FFF;

    &::placeholder {
      color: #D1D2DD;
    }
  }
}

.select-block {
  position: relative;

  .arrow-down {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 23px;
    height: 18px;
    transform: translateY(-50%);
  }
}

.submit-btn {
  .submit-btn;
  margin-bottom: 45px;
}
</style>
